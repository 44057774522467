import React from "react";
import Dashboard from "./Pages/Dashboard";
import LogIn from "./Pages/Logn";
import Upload from "./Pages/Upload";

import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import ProtectedRoute from "./guards/ProtectedRoute";
import { FileDataProvider } from "./context/FileDataContext";

const App = () => {
  return (
    <FileDataProvider>
      <Router>
        <div>
          <section>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Dashboard />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/upload" element={<Upload />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/upload" element={<Upload />} />
              </Route>
              <Route path="/login" element={<LogIn />} />
            </Routes>
          </section>
        </div>
      </Router>
    </FileDataProvider>
  );
};

export default App;
