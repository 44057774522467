import React from 'react';
import FinancialDashboard from '../components/FinancialDashboard';
import './index.css';
import Toolbar  from '../components/ToolBar';

const Dashboard = () => {

  return (
    <div className="App">
      <Toolbar/>
      <FinancialDashboard />
    </div>
  );
};

export default Dashboard;
