import React from 'react';

const formatAmount = (amount) => {
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);
  let result;

  if (absAmount >= 1000000) {
    result = (absAmount / 1000000).toFixed(1);
    if (result.endsWith('.0')) {
      result = result.slice(0, -2);
    }
    result += 'M';
  } else if (absAmount >= 1000) {
    result = (absAmount / 1000).toFixed(1);
    if (result.endsWith('.0')) {
      result = result.slice(0, -2);
    }
    result += 'K';
  } else {
    result = Math.round(absAmount).toString(); // Round to nearest dollar
  }

  return (isNegative ? '-$' : '$') + result;
};

const BalanceHeader = ({ title, total, expenses, income }) => {
  return (
    <div className="mb-4">
      <h3 className="text-4xl font-bold mb-2">{title}</h3>
      <div className="flex">
        <div className="font-mono">
          <div className="text-4xl mb-2">
            <span className="text-green-500 font-bold">Income: </span>
          </div>
          <div className="text-4xl mb-2">
            <span className="text-red-500 font-bold">Expense: </span>
          </div>
          <div className="text-4xl">
            <span className="text-blue-600 font-bold">Balance: </span>
          </div>
        </div>
        <div className="font-mono ml-64">
          <div className="text-4xl mb-2">
            <span className="text-green-500 font-bold text-right w-56 inline-block">{formatAmount(income)}</span>
          </div>
          <div className="text-4xl mb-2">
            <span className="text-red-500 font-bold text-right w-56 inline-block">{formatAmount(-Math.abs(expenses))}</span>
          </div>
          <div className="text-4xl">
            <span className="text-blue-600 font-bold text-right w-56 inline-block">{formatAmount(total)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceHeader;
