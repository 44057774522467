import React from 'react';

const NoDataSelected = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <p className="text-2xl text-gray-500">No data selected. Please select a year.</p>
    </div>
  );
};

export default NoDataSelected;