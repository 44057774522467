/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { loadData, DEFAULT_YEAR } from '../data/data';
import Sidebar from './Sidebar';
import BarCharts from './BarCharts';
import AccountList from './AccountList';
import SearchBar from './SearchBar';
import NoDataSelected from './NoDataSelected';
import FileDataContext  from '../context/FileDataContext';
import { CircularProgress, Box } from '@mui/material';
import logger from '../utils/logger';

const FinancialDashboard = () => {
  const [dataProcessor, setDataProcessor] = useState(null);
  const [data, setData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [lastSelectedNode, setLastSelectedNode] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [selectedMonths, setSelectedMonths] = useState([...Array(12).keys()]);
  const [error, setError] = useState(null);
  const [, setSelectedMonth] = useState(null);
  const [selectedYears, setSelectedYears] = useState([DEFAULT_YEAR]);
  const [allYears, setAllYears] = useState();

  const { fileDataName } = useContext(FileDataContext);

  const updateDataRef = useRef();

  const getNodePath = useCallback((node) => {
    const path = [];
    let currentNode = node;
    while (currentNode) {
      path.unshift(currentNode.name);
      currentNode = currentNode.parent;
    }
    return path.join('/');
  }, []);

  const findNodeByPath = useCallback((root, path) => {
    logger.log('Finding node by path:', path);
    const parts = path.split('/');
    let currentNode = root;

    if (parts[0] !== root.name) {
      logger.log('Root node name does not match:', root.name);
      return null;
    }

    for (let i = 1; i < parts.length; i++) {
      const part = parts[i];
      logger.log('Searching for part:', part);
      if (!currentNode.children) {
        logger.log('No children found for current node');
        return null;
      }
      currentNode = currentNode.children.find(child => child.name === part);
      if (!currentNode) {
        logger.log('Child not found:', part);
        return null;
      }
      logger.log('Found child:', currentNode.name);
    }
    return currentNode;
  }, []);

  const addParentReferences = useCallback((node, parent = null) => {
    node.parent = parent;
    if (node.children) {
      node.children.forEach(child => addParentReferences(child, node));
    }
  }, []);

  const updateData = useCallback((processor, currentNodePath = null) => {
    logger.log('Updating data');
    try {
      if (selectedYears.length === 0 || selectedMonths.length === 0) {
        if (selectedNode) {
          setLastSelectedNode(selectedNode);
        }
        setData(null);
        setSelectedNode(null);
        setExpandedNodes({});
        return null;
      }

      let loadedData = processor(selectedYears, selectedMonths);
      
      logger.log('Loaded data:', loadedData);
  
      addParentReferences(loadedData);
      
      let newSelectedNode;
      if (lastSelectedNode) {
        const lastNodePath = getNodePath(lastSelectedNode);
        newSelectedNode = findNodeByPath(loadedData, lastNodePath) || loadedData;
        setLastSelectedNode(null);
      } else if (selectedNode) {
        const currentPath = getNodePath(selectedNode);
        newSelectedNode = findNodeByPath(loadedData, currentPath) || loadedData;
      } else if (currentNodePath) {
        newSelectedNode = findNodeByPath(loadedData, currentNodePath) || loadedData;
      } else {
        newSelectedNode = loadedData;
      }

      const newExpandedNodes = { ...expandedNodes };
      let node = newSelectedNode;
      while (node.parent) {
        newExpandedNodes[getNodePath(node)] = true;
        node = node.parent;
      }
      return { loadedData, newSelectedNode, newExpandedNodes };
      
    } catch (err) {
      console.error('Error processing data:', err);
      setError('Failed to process data. Please check the console for more details.');
      return null;
    }
  }, [expandedNodes, addParentReferences, findNodeByPath, getNodePath, selectedYears, selectedMonths, selectedNode, lastSelectedNode]);

  useEffect(() => {
    updateDataRef.current = updateData;
  }, [updateData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        logger.log('Fetching data...');
        const { computeForSelectYears, allYears } = await loadData(fileDataName);
        setAllYears(allYears)
        logger.log('Data processor received:', computeForSelectYears);
        setDataProcessor(() => computeForSelectYears);
        const result = updateDataRef.current(computeForSelectYears);
        if (result) {
          setData(result.loadedData);
          setSelectedNode(result.newSelectedNode);
          setExpandedNodes(result.newExpandedNodes);
        }
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load data. Please check the console for more details.');
      }
    };
    fetchData();
  }, [fileDataName]);

  useEffect(() => {
    if (dataProcessor) {
      const result = updateDataRef.current(dataProcessor);
      if (result) {
        setData(result.loadedData);
        setSelectedNode(result.newSelectedNode);
        setExpandedNodes(result.newExpandedNodes);
      }
    }
  }, [selectedYears, dataProcessor, selectedMonths]);

  const handleNodeClick = useCallback((node) => {
    logger.log('Node clicked:', node);
    setSelectedNode(node);
    expandNode(node);
  }, []);

  const toggleExpand = useCallback((node) => {
    const nodePath = getNodePath(node);
    logger.log('Toggling expand for:', nodePath);
    setExpandedNodes(prev => ({
      ...prev,
      [nodePath]: !prev[nodePath]
    }));
  }, [getNodePath]);

  const expandNode = useCallback((node) => {
    logger.log('Expanding node:', node);
    setExpandedNodes(prev => {
      const newExpandedNodes = { ...prev };
      let currentNode = node;
      while (currentNode) {
        const nodePath = getNodePath(currentNode);
        newExpandedNodes[nodePath] = true;
        currentNode = currentNode.parent;
      }
      logger.log('New expanded nodes:', newExpandedNodes);
      return newExpandedNodes;
    });
  }, [getNodePath]);

  const handleSearchSelect = useCallback((result) => {
    logger.log('Search result selected:', result);
    const node = findNodeByPath(data, result.path);
    if (node) {
      logger.log('Node found:', node);
      setSelectedNode(node);
      expandNode(node);

      const nodeElement = document.getElementById(`node-${getNodePath(node)}`);
      if (nodeElement) {
        logger.log('Scrolling to node element');
        nodeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        logger.log('Node element not found in DOM');
      }
    } else {
      console.error('Node not found for path:', result.path);
    }
  }, [data, findNodeByPath, getNodePath, expandNode]);

  const handleYearSelect = useCallback((years) => {
    setSelectedYears(years);
  }, []);

  const handleMonthsSelect = useCallback((month) => {
    setSelectedMonths(month);
  }, []);

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  if (!data && selectedYears.length > 0 && selectedMonths.length > 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  logger.log('Rendering dashboard with data:', data);
  logger.log('Selected node:', selectedNode);
  logger.log('Expanded nodes:', expandedNodes);

  const isAccountView = selectedNode && selectedNode.individualAmounts;

  return (
    <div className="flex flex-col h-screen">
      <div className="p-4 bg-gray-100">
        <SearchBar 
          allYears={allYears}
          data={data} 
          onSelect={handleSearchSelect} 
          onYearSelect={handleYearSelect}
          selectedYears={selectedYears}
          selectedMonth={selectedMonths}
          handleMonthToggle={handleMonthsSelect}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {selectedYears.length === 0 || selectedMonths.length === 0 ? (
          <NoDataSelected />
        ) : (
          <>
            <Sidebar
              allYears={allYears}
              data={data}
              selectedNode={selectedNode}
              expandedNodes={expandedNodes}
              handleNodeClick={handleNodeClick}
              toggleExpand={toggleExpand}
              getNodePath={getNodePath}
            />
            <div className="flex-1 flex flex-col overflow-hidden">
              <div className="flex-1 p-3 pr-5 pb-8 overflow-hidden">
                {isAccountView ? (
                  <AccountList
                    selectedNode={selectedNode}
                    selectedMonth={selectedMonths}
                    setSelectedMonth={setSelectedMonth}
                    selectedYears={selectedYears}
                  />
                ) : (
                  <BarCharts
                    selectedNode={selectedNode}
                    selectedMonth={selectedMonths}
                    setSelectedMonth={setSelectedMonth}
                    handleNodeClick={handleNodeClick}
                    selectedYears={selectedYears}
                    expandNode={expandNode}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FinancialDashboard;
