import Papa from 'papaparse';
import { ref, getDownloadURL, listAll } from 'firebase/storage';
import { storageService } from '../Firebase';
import logger from '../utils/logger';


//testing the push

export const loadData = async () => {
  logger.log('Starting to load data...');
  let combinedCsvData = ''
    const storageRef = ref(storageService);
    const res = await listAll(storageRef);
    logger.log('Fetched files from Firebase Storage:', res);

    if (res.items.length === 0) {
      throw new Error('No files found in Firebase Storage.');
    }
    for (const fileRef of res.items) {
      const fileUrl = await getDownloadURL(fileRef);
      logger.log('Download URL for file:', fileUrl)
      const response = await fetch(fileUrl);
      logger.log('Fetched CSV file from Firebase:', fileRef.name);
      
      const csvData = await response.text();
      logger.log(csvData);
      logger.log('CSV data loaded for file:', fileRef.name);
      combinedCsvData += csvData + '\n'; // Adds a newline to separate CSV files
    }
    
  
    const results = Papa.parse(combinedCsvData, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.trim(),
      transform: (value, field) => {
        if (typeof value === 'string') {
          value = value.trim();
        }
        if (field === 'Debit') {
          // Remove all spaces, $ and commas
          let cleanValue = value.replace(/\s+/g, '').replace(/[$,]/g, '');

          // Check if the value is in parentheses (negative)
          if (cleanValue.startsWith('(') && cleanValue.endsWith(')')) {
            return parseFloat(cleanValue.slice(1, -1));
          }

          return -parseFloat(cleanValue); // Negate the value
        }
        return value;
      },
    });
    logger.log('CSV parsed, row count:', results.data.length);
    // Clean and filter the data
    const cleanData = results.data.filter(row => {
      const reasons = [];
      if (!row.Date) reasons.push('Missing Date');
      if (!row.Account) reasons.push('Missing Account');
      if (isNaN(row.Debit)) reasons.push('Invalid Debit');
      if (!row.Category) reasons.push('Missing Category');
      if (!row.Group) reasons.push('Missing Group');
      
      const isValid = reasons.length === 0;
      if (!isValid) {
        logger.log('Removed row:', row, 'Reasons:', reasons.join(', '));
      }
      return isValid;
    });

    logger.log('Data cleaned, row count:', cleanData.length);

    // Function to process data
    const processData = (data, selectedYears, selectedMonths) => {
      if (selectedYears.length === 0) {
        logger.log('No years selected, returning empty data structure');
        return {
          name: 'Summary',
          amount: 0,
          monthlyData: Array(12).fill(0).map((_, index) => ({
            month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][index],
            amount: 0,
          })),
          children: [],
        };
      }

      let filteredData = data.filter(item => selectedYears.includes(new Date(item.Date).getFullYear()));
      filteredData = filteredData.filter(item => selectedMonths.includes(new Date(item.Date).getMonth()));
      logger.log("=========================")
      logger.log(selectedMonths)
      logger.log("=========================")

      const groupedData = filteredData.reduce((acc, item) => {
        if (!acc[item.Group]) {
          acc[item.Group] = {};
        }
        if (!acc[item.Group][item.Category]) {
          acc[item.Group][item.Category] = {};
        }
        if (!acc[item.Group][item.Category][item.Account]) {
          acc[item.Group][item.Category][item.Account] = [];
        }
        acc[item.Group][item.Category][item.Account].push({
          name: item.Name || '',
          amount: item.Debit, // Now correctly parsed as a negative number
          date: new Date(item.Date),
          memo: item.Memo || '',
          type: item.Type || '',
          num: item.Num || '',
          split: item.Split || '',
        });
        return acc;
      }, {});

      const calculateTotal = (items) => items.reduce((sum, item) => sum + item.amount, 0);

      const buildHierarchy = (data) => {
        logger.log('Building hierarchy...');
        const root = {
          name: 'Summary',
          amount: 0,
          monthlyData: Array(12).fill(0).map(() => ({ amount: 0 })),
          children: [],
        };

        Object.entries(data).forEach(([groupName, categories]) => {
          const group = {
            name: groupName,
            amount: 0,
            monthlyData: Array(12).fill(0).map(() => ({ amount: 0 })),
            children: [],
          };

          Object.entries(categories).forEach(([categoryName, accounts]) => {
            const category = {
              name: categoryName,
              amount: 0,
              monthlyData: Array(12).fill(0).map(() => ({ amount: 0 })),
              children: [],
            };

            Object.entries(accounts).forEach(([accountName, transactions]) => {
              const account = {
                name: accountName,
                amount: calculateTotal(transactions),
                monthlyData: Array(12).fill(0).map(() => ({ amount: 0 })),
                individualAmounts: transactions,
              };

              transactions.forEach(transaction => {
                const month = transaction.date.getMonth();
                account.monthlyData[month].amount += transaction.amount;
                category.monthlyData[month].amount += transaction.amount;
                group.monthlyData[month].amount += transaction.amount;
                root.monthlyData[month].amount += transaction.amount;
              });

              category.amount += account.amount;
              category.children.push(account);
            });

            group.amount += category.amount;
            group.children.push(category);
          });

          root.amount += group.amount;
          root.children.push(group);
        });

        root.monthlyData = root.monthlyData.map((data, index) => ({
          month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][index],
          amount: data.amount,
        }));

        logger.log('Hierarchy built');
        return root;
      };

      return buildHierarchy(groupedData);
    };
    const allYears = [...new Set(cleanData.map(row => new Date(row.Date).getFullYear()))];

    // Return a function that processes data based on selected years
    return {computeForSelectYears: (selectedYears, selectedMonths) => {
      const result = processData(cleanData, selectedYears, selectedMonths);
      logger.log('Final data structure:', JSON.stringify(result, null, 2));
      return result;
    }, allYears};
  }

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

// Set the default year to 2024
export const DEFAULT_YEAR = 2024;
