import React, { createContext, useState } from 'react';

const FileDataContext = createContext();

export const FileDataProvider = ({ children }) => {
  const [files, setFiles] = useState([]);

  return (
    <FileDataContext.Provider value={{ files, setFiles }}>
      {children}
    </FileDataContext.Provider>
  );
};

export default FileDataContext;
