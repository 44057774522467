import React, { useState, useEffect, useRef } from "react";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";
import logger from '../utils/logger';

const SearchBar = ({
  data,
  onSelect,
  onYearSelect,
  selectedYears,
  selectedMonth,
  handleMonthToggle,
  allYears,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const flattenData = (node, path = []) => {
    let results = [];
    const newPath = [...path, node.name];
    let type;

    if (newPath.length === 1) {
      type = "Global";
    } else if (newPath.length === 2) {
      type = "Group";
    } else if (newPath.length === 3) {
      type = "Category";
    } else {
      type = "Account";
    }

    results.push({
      name: node.name,
      type,
      path: newPath.join("/"),
    });

    if (node.children) {
      node.children.forEach((child) => {
        results = results.concat(flattenData(child, newPath));
      });
    }
    return results;
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      const flatData = flattenData(data);
      logger.log("Flattened data:", flatData);
      const filtered = flatData.filter((item) =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
      logger.log("Filtered results:", filtered);
      setSearchResults(filtered);
      setIsDropdownVisible(true);
      setSelectedIndex(-1);
    } else {
      setSearchResults([]);
      setIsDropdownVisible(false);
      setSelectedIndex(-1);
    }
  };

  const handleSelect = (result) => {
    logger.log("Search result selected:", result);
    onSelect(result);
    setSearchTerm("");
    setIsDropdownVisible(false);
    setSelectedIndex(-1);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleKeyDown = (e) => {
    if (isDropdownVisible) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
          break;
        case "Enter":
          e.preventDefault();
          if (selectedIndex >= 0 && selectedIndex < searchResults.length) {
            handleSelect(searchResults[selectedIndex]);
          }
          break;
        default:
          break;
      }
    }
  };
  const handleYearToggle = (e) => {
    onYearSelect(e.target.value);
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="relative w-full max-w-md">
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          placeholder="Search for global, group, category, or account"
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {isDropdownVisible && searchResults.length > 0 && (
          <div
            ref={dropdownRef}
            className="absolute w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto z-10"
          >
            {searchResults.map((result, index) => (
              <div
                key={index}
                onClick={() => handleSelect(result)}
                className={`px-4 py-2 cursor-pointer ${
                  index === selectedIndex ? "bg-blue-100" : "hover:bg-gray-100"
                }`}
              >
                <span className="font-semibold">{result.name}</span>
                <span className="text-sm text-gray-500 ml-2">
                  ({result.type})
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex">
        <FormControl fullWidth variant="outlined">
          <InputLabel id="year-select">Select year</InputLabel>
          <Select
            id="year-select"
            multiple
            className="w-[200px] h-[65px]"
            label="Select Year"
            value={selectedYears}
            onChange={(e) => handleYearToggle(e)} // Passing the array directly
            renderValue={(selected) => (
              <Stack gap={1} direction="row" flexWrap="wrap">
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Stack>
            )}
          >
            {allYears
              .sort((a, b) => a - b) // Sorting in ascending order
              .map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginLeft: "20px" }}
        >
          <InputLabel id="month-select">Select Month</InputLabel>
          <Select
            id="month-select"
            multiple
            label="Select Month"
            className="w-[200px] h-[65px]"
            value={selectedMonth}
            onChange={(e) => handleMonthToggle(e.target.value)}
          >
            {months.map((month, index) => (
              <MenuItem key={index} value={index}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="flex flex-col ml-2">
          <button
            onClick={() => {
              logger.log("AccountList - All Months button clicked");
              handleMonthToggle([...Array(12).keys()]); // Resetting to all months
            }}
            style={{ marginBottom: "5px", height: '30px' }} // Adding margin-bottom to create space between buttons
            className="bg-blue-500 text-white font-bold rounded w-[160px]"
          >
            Reset to all months
          </button>

          <button
            onClick={() => {
              logger.log("AccountList - Deselect All button clicked");
              handleMonthToggle([]); // Deselecting all months
            }}
            className="bg-blue-500 text-white font-bold rounded w-[160px] h-[30px]"
          >
            Deselect all
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
