import React, { useState, useEffect, useCallback } from 'react';
import { ChevronRight, ChevronDown } from 'lucide-react';
import { formatCurrency } from '../utils/formatters';

const Sidebar = ({ data, selectedNode, handleNodeClick, getNodePath }) => {
  const [width, setWidth] = useState(() => {
    const savedWidth = localStorage.getItem('sidebarWidth');
    return savedWidth ? parseInt(savedWidth) : 700;
  });
  const [isResizing, setIsResizing] = useState(false);
  const [manuallyExpandedNodes, setManuallyExpandedNodes] = useState({});

  const startResizing = useCallback((e) => {
    setIsResizing(true);
    e.preventDefault();
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    localStorage.setItem('sidebarWidth', width.toString());
  }, [width]);

  const resize = useCallback((e) => {
    if (isResizing) {
      const newWidth = e.clientX;
      if (newWidth >= 200) {
        setWidth(newWidth);
      }
    }
  }, [isResizing]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', stopResizing);
    }

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing, resize, stopResizing]);

  const sortChildren = (children) => {
    return children.sort((a, b) => {
      if ((a.amount >= 0 && b.amount >= 0) || (a.amount < 0 && b.amount < 0)) {
        return Math.abs(b.amount) - Math.abs(a.amount);
      }
      return b.amount - a.amount;
    });
  };

  const getStyleForDepth = (depth) => {
    const styles = [
      'bg-gray-100 text-gray-900',
      'bg-blue-50 text-blue-900',
      'bg-gray-50 text-gray-900',
    ];
    return depth < styles.length ? styles[depth] : '';
  };

  const isNodeExpanded = (node) => {
    const nodePath = getNodePath(node);
    
    // Check if node is manually expanded
    if (manuallyExpandedNodes[nodePath] !== undefined) {
      return manuallyExpandedNodes[nodePath];
    }

    // Default expansion logic for selected node path
    if (!selectedNode) return false;
    if (!node.parent) return true;
    if (node === selectedNode) return true;

    let currentNode = selectedNode;
    while (currentNode.parent) {
      if (currentNode.parent === node) {
        return true;
      }
      currentNode = currentNode.parent;
    }

    return false;
  };

  const handleExpandClick = (e, node) => {
    e.stopPropagation();
    const nodePath = getNodePath(node);
    setManuallyExpandedNodes(prev => ({
      ...prev,
      [nodePath]: !isNodeExpanded(node)
    }));
  };

  const handleRectangleClick = (node) => {
    // Reset manually expanded nodes when selecting a new node
    setManuallyExpandedNodes({});
    handleNodeClick(node);
  };

  const renderTree = (node, depth = 0) => {
    const hasChildren = node.children && node.children.length > 0;
    const isExpanded = isNodeExpanded(node);
    const style = getStyleForDepth(depth);
    const nodePath = getNodePath(node);

    return (
      <div key={nodePath} className={`${depth > 0 ? 'border-l border-gray-300 pl-2' : ''}`}>
        <div className="flex items-center mb-1">
          {/* Expand/Collapse Square */}
          {hasChildren && (
            <div
              className="w-6 h-6 flex items-center justify-center border border-black cursor-pointer mr-2"
              onClick={(e) => handleExpandClick(e, node)}
            >
              {isExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
            </div>
          )}
          {!hasChildren && <div className="w-6 mr-2" />}

          {/* Main Rectangle */}
          <div
            className={`flex-1 flex items-center justify-between py-2 px-3 ${style} rounded-md ${depth < 3 ? 'shadow-sm' : ''} cursor-pointer hover:bg-opacity-80`}
            onClick={() => handleRectangleClick(node)}
          >
            <span
              className={`text-sm ${selectedNode === node ? 'font-bold' : ''} ${depth >= 3 ? 'text-gray-700' : ''}`}
            >
              {node.name}
            </span>
            <span className={`text-sm ${hasChildren ? 'font-semibold' : ''} ${depth >= 3 ? 'text-gray-600' : ''}`}>
              {formatCurrency(node.amount)}
            </span>
          </div>
        </div>
        {isExpanded && hasChildren && (
          <div className="mt-1 ml-4">
            {sortChildren(node.children).map(child => 
              renderTree(child, depth + 1)
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: `${width}px` }} className="relative flex-shrink-0 p-4 overflow-auto border-r">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-gray-900">Financial Structure</h2>
      </div>
      {renderTree(data, 0)}
      <div
        className="absolute top-0 right-0 bottom-0 w-1 cursor-col-resize bg-transparent hover:bg-gray-300 transition-colors"
        onMouseDown={startResizing}
      />
    </div>
  );
};

export default Sidebar;
