import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from '../components/ToolBar';
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { storageService } from '../Firebase';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Paper, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import FileDataContext from '../context/FileDataContext';
import Papa from 'papaparse';
import Chip from '@mui/material/Chip';
import CircularProgress from "@mui/material/CircularProgress";

const storageRef = ref(storageService, 'gs://financial-dashboard-prod.appspot.com');

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function Upload() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const { files, setFiles } = useContext(FileDataContext);
  const [loading, setLoadingFile] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [_, setFileDateRanges] = useState([]);
  const [overlapMap, setOverlapMap] = useState({});

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const res = await listAll(storageRef);
        const fileList = res.items.map((itemRef) => ({
          id: itemRef.fullPath,
          name: itemRef.name,
          fullPath: itemRef.fullPath,
        }));
        setFiles(fileList);
      } catch (error) {
        console.error('Error listing files:', error);
      }
    };
    fetchFiles();
  }, [setFiles]);

  const findTheMinMaxDate = async () => {
    const res = await listAll(storageRef);
    const fileDateRanges = [];
    for (const fileRef of res.items) {
      const fileUrl = await getDownloadURL(fileRef);
      const response = await fetch(fileUrl);
      const csvData = await response.text();
      const results = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.trim(),
      });
      const dates = results.data.map(row => new Date(row.Date)).filter(date => !isNaN(date));
      if (dates.length === 0) continue;
      const minDate = new Date(Math.min(...dates));
      const maxDate = new Date(Math.max(...dates));
      fileDateRanges.push({
        fileName: fileRef.name,
        minDate: minDate.toISOString(),
        maxDate: maxDate.toISOString(),
      });
    }

    // Create a map to store overlapping files
    const overlapMap = {};
    for (let i = 0; i < fileDateRanges.length; i++) {
      for (let j = i + 1; j < fileDateRanges.length; j++) {
        const file1 = fileDateRanges[i];
        const file2 = fileDateRanges[j];
        if (file1.minDate <= file2.maxDate && file1.maxDate >= file2.minDate) {
          if (!overlapMap[file1.fileName]) {
            overlapMap[file1.fileName] = [];
          }
          if (!overlapMap[file2.fileName]) {
            overlapMap[file2.fileName] = [];
          }
          overlapMap[file1.fileName].push(file2.fileName);
          overlapMap[file2.fileName].push(file1.fileName);
        }
      }
    }

    return { fileDateRanges, overlapMap };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { fileDateRanges, overlapMap } = await findTheMinMaxDate();
        setFileDateRanges(fileDateRanges);
        setOverlapMap(overlapMap); // Store the overlap map
      } catch (error) {
        console.error('Error fetching date ranges:', error);
      }
    };
    fetchData();
  }, [setFileDateRanges]);

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      try {
        setLoadingFile(true);
        const newFiles = [];
        for (const file of selectedFiles) {
          const fileRef = ref(storageService, `${file.name}`);
          await uploadBytes(fileRef, file);
          const fileURL = await getDownloadURL(fileRef);
          newFiles.push({
            id: fileRef.fullPath,
            name: file.name,
            fullPath: fileRef.fullPath,
            url: fileURL,
          });
        }
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setLoadingFile(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        setLoadingFile(false);
      }
    }
  };

  const handleDeleteFile = async (file) => {
    try {
      const fileRef = ref(storageService, file.fullPath);
      await deleteObject(fileRef);
      setFiles((prevFiles) => prevFiles.filter(f => f.id !== file.fullPath));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setFileContent('');
  };

  const columns = [
    {
      field: 'name',
      headerName: 'File Name',
      width: 200,

    },
    {
      field: 'overlapWith',
      headerName: 'Overlaps With',
      width: 300,
      renderCell: (params) => (
          <div style={{ display: 'flex', gap: '10px' }}>
            {overlapMap[params.row.name] && overlapMap[params.row.name].length > 0 ? (
              overlapMap[params.row.name].map((file, index) => (
                <Chip
                  key={index}
                  label={file}
                  color="primary"
                />
              ))
            ) : (
              "No overlap"
            )}
          </div>        
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 280,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDeleteFile(params.row)}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <Toolbar />
      <Container>
        <Typography variant="h4" style={{ marginTop: '30px' }} gutterBottom>
          Upload Files
        </Typography>
        <div style={{ width: '200px', marginBottom: '20px' }}>
        <Button
      component="label"
      variant="contained"
      tabIndex={-1}
      startIcon={!loading && <CloudUploadIcon />} // Show icon when not loading
      disabled={loading} // Disable the button while loading
    >
      {loading ? (
      <>
      <CircularProgress size={24} color="inherit" /> 
      <span style={{ marginLeft: 8 }}>Loading...</span> {/* Loading text */}
    </>  
  ) : (
        "Upload files"
      )}
      <VisuallyHiddenInput
        type="file"
        onChange={handleFileChange}
        multiple
      />
    </Button>
        </div>
        <Typography variant="h5" gutterBottom>
          Data File Available
        </Typography>

        <Paper style={{ height: '400px', width: '70%', minWidth: '800px' }}>
          <DataGrid
            rows={files}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
          />
        </Paper>
        <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>File Content</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              rows={10}
              variant="outlined"
              fullWidth
              value={fileContent}
              InputProps={{
                readOnly: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
