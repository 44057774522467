import React, { useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import {auth} from '../Firebase'

export function useAuth() {
  //
  const [currentUser, setCurrentUser] = useState();
  React.useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) =>
      setCurrentUser(user)
    );
    return unSubscribe;
  }, []);
  return currentUser;
}
