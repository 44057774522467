const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const logger = {
  log: (...args) => {
    if (isDebugMode) {
      console.log(...args);
    }
  },
};

export default logger;
