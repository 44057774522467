import Login from '../Pages/Logn'; // Assuming 'Logn' is a typo and should be 'Login'
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../hook/useAuth';
import logger from '../utils/logger';

export const ProtectedRoute = () => {
  const user = useAuth(); // Returns the user authentication status

  logger.log('/////user authenticated:', user);

  // Check if user is still being fetched/undefined
  if (typeof user === 'undefined') {
    return <h1>Loading.....</h1>;
  }

  // If user is authenticated, render child routes via Outlet
  if (user) {
    return <Outlet />;
  }

  // If user is not authenticated, render the Login component
  return <Login />;
};

export default ProtectedRoute;
