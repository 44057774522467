const formatCurrency = (amount, useParentheses = false) => {
  // Convert the amount to a string and remove any spaces
  let stringAmount = amount.toString().replace(/\s/g, '');
  
  // Check if the amount is in parentheses (negative value)
  const isNegative = stringAmount.startsWith('(') && stringAmount.endsWith(')');
  if (isNegative) {
    // Remove parentheses and make the number negative
    stringAmount = '-' + stringAmount.slice(1, -1);
  }

  // Convert the cleaned string back to a number
  const numericAmount = parseFloat(stringAmount);

  // Check if the conversion was successful
  if (isNaN(numericAmount)) {
    console.error(`Invalid amount: ${amount}`);
    return 'Invalid Amount';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAmount = formatter.format(Math.abs(numericAmount));

  if (numericAmount < 0) {
    return useParentheses ? `(${formattedAmount})` : `-${formattedAmount}`;
  }

  return formattedAmount;
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const formatDollarAmount = (amount) => {
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);
  let result;

  if (absAmount >= 1000000) {
    result = (absAmount / 1000000).toFixed(1);
    if (result.endsWith('.0')) {
      result = result.slice(0, -2);
    }
    result += 'M';
  } else if (absAmount >= 1000) {
    result = Math.floor(absAmount / 1000) + 'K';
  } else {
    result = Math.floor(absAmount).toString();
  }

  return isNegative ? `(${result})` : result;
};

const formatDollarRounded = (amount) => {
  const roundedAmount = Math.round(Math.abs(amount));
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  const formattedAmount = formatter.format(roundedAmount);
  
  return amount < 0 ? `(${formattedAmount})` : formattedAmount;
};

export { formatCurrency, formatDate, formatDollarAmount, formatDollarRounded };