import PageOverlay from "../components/PageOverlay";
import "./login.css";
import menorahVideo from "../assets/menorah.mp4";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Firebase'
import { useNavigate } from 'react-router-dom'
import logger from '../utils/logger';

export default function LogIn() {
    const [errorMessage, setErrorMessage] = useState(false)
    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        signInWithEmailAndPassword(auth, data.get('username'), data.get('password'))
            .then((userCredential) => {
                const user = userCredential.user;
                navigate("/")
                logger.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                logger.log(errorCode, errorMessage)
                setErrorMessage(errorMessage)
            });
    }

    return (
        <PageOverlay video={menorahVideo}>
            <Box 
                component="form" 
                onSubmit={onLogin} 
                noValidate 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                }}
            >
                <TextField
                    required
                    fullWidth
                    id="username"
                    label="username"
                    name="username"
                    autoComplete="username"
                    error={errorMessage}
                    autoFocus
                    size="small"
                    margin="none"
                />
                <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    error={errorMessage}
                    autoComplete="current-password"
                    size="small"
                    margin="none"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1 }}
                >
                    Sign In
                </Button>
                {errorMessage && <div style={{ color: 'red', fontSize: '12px', marginTop: '-4px' }}>Wrong username or password</div>}
            </Box>
        </PageOverlay>
    );
}
