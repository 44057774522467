import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { createPortal } from 'react-dom';
import menorahImage from '../assets/menorah.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 5000,
    overflow: 'hidden',
    backgroundColor: 'black',
    backgroundImage: `url(${menorahImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    width: '400px',
    borderRadius: '8px',
    background: 'white',
    overflow: 'hidden',
    filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25))',
    position: 'relative',
    zIndex: 2,
    padding: '15px 20px',
    marginBottom: '20vh',
  },
  content: {
    textAlign: 'center',
  },
  videoBackground: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100vh',
    width: 'auto',
    zIndex: 1,
    objectFit: 'cover',
  }
}));

const PageOverlay = ({ video, children }) => {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = '';
    };
  }, []);

  return createPortal(
    <div className={classes.root}>
      <video autoPlay muted loop className={classes.videoBackground}>
        <source src={video} type="video/mp4" />
      </video>
      <div className={classes.container}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
}

export default PageOverlay;
