// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCRtNyKTOkRbjAm_HxAUyBgk-2_i3ahVzU",
  authDomain: "financial-dashboard-prod.firebaseapp.com",
  projectId: "financial-dashboard-prod",
  storageBucket: "financial-dashboard-prod.appspot.com",
  messagingSenderId: "989130861571",
  appId: "1:989130861571:web:0b9e59218904a3525af236",
  measurementId: "G-02DFNRT55R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const auth = getAuth(app);
export const storageService = storage;
export default app;
